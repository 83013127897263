<template>
  <div class="warp">
    <div class="item-list" v-for="(item, index) in itemList" :key="index" @click="editClick(item)">
      <h3>{{item.storeName}}</h3>
      <p><span>存储点大小: {{item.storeArea}}</span><span>贮储能力: {{item.storeCapacity}}</span></p>
      <p><span>责任人: {{item.managerName}}</span><span>电话: {{item.phone}}</span></p>
      <p><span>地址: {{item.address}}</span></p>
    </div>
    <div class="btn">
      <van-button round block type="info" @click="addClick">添加新的贮存点</van-button>
      <van-button round block type="default" @click="gobackClick">返回</van-button>
    </div>
  </div>
</template>

<script>
import { storeList } from '@/api/notice.js'
export default {
  data () {
    return {
      itemList: []
    }
  },
  created() {
    this.storeList()
  },
  methods: {
    storeList() { // list
      storeList().then(res => {
        if (res.data.code === 200) {
          this.itemList = res.data.rows
        }
      })
    },
    gobackClick() { // 返回
      this.$router.push({path: 'Profileuser'})
    },
    editClick(item) { // 编辑
      this.$router.push({path: 'Addstorage', query: {id: item.id}})
    },
    addClick() { // 添加
      this.$router.push({path: 'Addstorage'})
    }
  }
}

</script>
<style lang="scss" scoped>
.warp {
  .item-list {
    margin-bottom: 10px;
    padding: 10px 15px;
    background-color: #fff;
    box-shadow: 0 0 10px 1px rgba($color: #000000, $alpha: 0.1);
    h3 {
      margin-bottom: 10px;
    }
    p {
      color: #999;
      margin: 5px 0;
      span {
        margin-right: 15px;
      }
    }
  }
}
::v-deep .van-button--round {
  border-radius: 3px;
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
  margin-bottom: 15px;
}
</style>
